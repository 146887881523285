/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Axios from "axios"

function SEO({ description, lang, meta, title, seo, image, language, translations, slug, templateClass = false }) {
  
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        file(relativePath: { eq: "default-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title; //default
  
  let locales = [];
  let i, altLanguageCode;

  if(typeof translations !== "undefined") {
    if(translations.length > 0){
      for (i in translations) {
        //altLanguageCode = (translations[i].language.code == "EN" ? '' : "/"+translations[i].language.code.toLowerCase());
        altLanguageCode = '';
        // convert fr_FR to fr-fr:
        locales.push({href: process.env.GATSBY_DOMAIN+altLanguageCode+translations[i].uri.replace("/products/", "/").replace("/product_categories/", "/"), hreflang: translations[i].language.locale.toLowerCase().replace("_", "-")});
        if(translations[i].language.code == "EN") {
          locales.push({href: process.env.GATSBY_DOMAIN+altLanguageCode+translations[i].uri.replace("/products/", "/").replace("/product_categories/", "/"), hreflang: `x-default`});
        }
      }
    }
  }

  let languageCode = "en-gb";
  // use the EN page as the default:
  if(typeof language !== "undefined") {
    if(language.code == "EN") {
      locales.push({href: process.env.GATSBY_DOMAIN+"/"+slug, hreflang: `x-default`});
    }
    languageCode = language.locale.toLowerCase().replace("_", "-");
  }

  let rootDomain = "https://www.wintechracing.com";
  let additionalTemplateClass = '';
  if(templateClass) {
    additionalTemplateClass = ' '+templateClass;
  }

  return (
    <Helmet>
      <html lang={languageCode} />

      <script type="text/javascript">
      {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NB36B92C');
      `}
      </script>

      <script type="text/javascript">
      {`
        if (typeof window !== 'undefined' && window) {
          fetch('https://ipapi.co/json/')
          .then(function(response) {
            response.json().then(jsonData => {
              const countryMappings = {
                'US' : { 'Name':'United States', 'Store':'US' },
                'AG' : { 'Name':'Antigua and Barbuda', 'Store':'US' },
                'BS' : { 'Name':'The Bahamas', 'Store':'US' },
                'BB' : { 'Name':'Barbados', 'Store':'US' },
                'BZ' : { 'Name':'Belize', 'Store':'US' },
                'CA' : { 'Name':'Canada', 'Store':'US' },
                'CR' : { 'Name':'Costa Rica', 'Store':'US' },
                'CU' : { 'Name':'Cuba', 'Store':'US' },
                'DM' : { 'Name':'Dominica', 'Store':'US' },
                'DO' : { 'Name':'Dominican Republic', 'Store':'US' },
                'SV' : { 'Name':'El Salvador', 'Store':'US' },
                'GD' : { 'Name':'Grenada', 'Store':'US' },
                'GT' : { 'Name':'Guatemala', 'Store':'US' },
                'HT' : { 'Name':'Haiti', 'Store':'US' },
                'HN' : { 'Name':'Honduras', 'Store':'US' },
                'JM' : { 'Name':'Jamaica', 'Store':'US' },
                'MX' : { 'Name':'Mexico', 'Store':'US' },
                'NI' : { 'Name':'Nicaragua', 'Store':'US' },
                'PA' : { 'Name':'Panama', 'Store':'US' },
                'KN' : { 'Name':'Saint Kitts and Nevis', 'Store':'US' },
                'LC' : { 'Name':'Saint Lucia', 'Store':'US' },
                'VC' : { 'Name':'Saint Vincent and the Grenadines', 'Store':'US' },
                'TT' : { 'Name':'Trinidad and Tobago', 'Store':'US' },
                'GB' : { 'Name':'United Kingdom', 'Store':'GB' },
                'FR' : { 'Name':'United Kingdom', 'Store':'GB' },
                'DE' : { 'Name':'United Kingdom', 'Store':'GB' },
                'ES' : { 'Name':'United Kingdom', 'Store':'GB' },
                'PL' : { 'Name':'United Kingdom', 'Store':'GB' },
                'ZH' : { 'Name':'United Kingdom', 'Store':'GB' },
              };
              let requiredStore = 'ROW';
              var hasRedirected = window.location.search.indexOf('geo-redirection');
              if(hasRedirected === -1) {

                if (countryMappings.hasOwnProperty(jsonData.country_code)) {
                  requiredStore = countryMappings[jsonData.country_code].Store;
                } 
                       if(requiredStore == 'US') {
                  window.location.assign('https://store.wintechracing.com'+window.location.pathname+'?geo-redirection=US');
                } else if ((requiredStore != 'FR') && (requiredStore != 'DE') && (requiredStore != 'ES') && (requiredStore != 'PL') && (requiredStore != 'ZH')) {
                  window.location.assign('https://www.wintechking.com'+window.location.pathname+'?geo-redirection='+jsonData.country_code);
                }
              }

            });
          })
          .catch(function(error) {
            console.log(error)
          });
        }
      `}
      </script>
      
      <body class={metaTitle.toLowerCase().replace(/[^a-z0-9]+/g,'-') + additionalTemplateClass} />

      <title>{seo ? seo.title : metaTitle}</title>
        {locales.map((locale, index) => (
            <link rel="alternate" href={locale.href} hreflang={locale.hreflang} />
        ))}
        <meta name="description" content={(seo && seo.metaDesc !== '' ? seo.metaDesc : metaDescription)} />
        <meta name="keywords" content={(seo && seo.metaKeywords !== '' ? seo.metaKeywords : '')} />
        <meta property="og:title" content={(seo && seo.opengraphTitle !== '' ? seo.opengraphTitle : (seo && seo.title ? seo.title : metaTitle))} />
        <meta property="og:description" content={(seo && seo.opengraphDescription !== '' ? seo.opengraphDescription : (seo && seo.metaDesc ? seo.metaDesc : metaDescription))} />
        <meta property="og:image" content={rootDomain+(image ? image.node.imageFile.childImageSharp.fluid.src : file.childImageSharp.fluid.src)} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={site.siteMetadata.author} />
        <meta name="twitter:title" content={(seo && seo.twitterTitle !== '' ? seo.twitterTitle : (seo && seo.title ? seo.title : metaTitle))} />
        <meta name="twitter:description" content={(seo && seo.twitterDescription !== '' ? seo.twitterDescription : (seo && seo.metaDesc ? seo.metaDesc : metaDescription))} />
        <meta name="twitter:image" content={rootDomain+(image ? image.node.imageFile.childImageSharp.fluid.src : file.childImageSharp.fluid.src)} />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  link: [],
  description: ``,
  translations: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
